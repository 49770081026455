import { combineReducers } from 'redux';
import {
    ACTIVE_INTERACTIVE_VIDEOS_FETCH_ERROR,
    ACTIVE_INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS,
    ACTIVE_INTERACTIVE_VIDEOS_FETCH_SUCCESS,
    ADD_INTERACTION_SET,
    ASSET_INFO_VIDEO_DESCRIPTION_SET,
    ASSET_INFO_VIDEO_SOURCE_FILE_SET,
    ASSET_INFO_VIDEO_TITLE_SET,
    EDIT_INTERACTION_CARD_HIDE,
    EDIT_INTERACTION_CARD_SHOW,
    INTERACTIVE_SEARCH_VALUE_SET,
    INTERACTIVE_TABS_VALUE_SET,
    INTERACTIVE_VIDEOS_FETCH_ERROR,
    INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS,
    INTERACTIVE_VIDEOS_FETCH_SUCCESS,
    IV_CHANNEL_TAB_VALUE_SET,
    MY_INTERACTIVE_VIDEOS_FETCH_ERROR,
    MY_INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS,
    MY_INTERACTIVE_VIDEOS_FETCH_SUCCESS,
    PUBLISH_BLOCK_LIST_ACCOUNTS_SET,
    PUBLISH_DIALOG_HIDE,
    PUBLISH_DIALOG_SHOW,
    PUBLISH_ELIGIBLE_COLLECTIONS_SET,
    PUBLISH_SCHEDULE_START_TIME_SET,
    PUBLISH_SCHEDULE_TAB_SET,
    PUBLISH_VISIBILITY_TAB_SET,
    PUBLISH_WHITELIST_SET,
    PUBLISH_WHITELIST_UNSET,
    SELECTED_COLLECTION_SET,
    TOKEN_PROTECTED_SWITCH_SET,
    UPCOMING_INTERACTIVE_VIDEOS_FETCH_ERROR,
    UPCOMING_INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS,
    UPCOMING_INTERACTIVE_VIDEOS_FETCH_SUCCESS,
    FETCH_GATED_INTERACTIONS_IN_PROGRESS,
    FETCH_GATED_INTERACTIONS_SUCCESS,
    FETCH_GATED_INTERACTIONS_ERROR,
    FETCH_VIDEO_MINTS_IN_PROGRESS,
    FETCH_VIDEO_MINTS_SUCCESS,
    FETCH_VIDEO_MINTS_ERROR,
    FETCH_PUBLISHED_VIDEOS_IN_PROGRESS,
    FETCH_PUBLISHED_VIDEOS_SUCCESS,
    FETCH_PUBLISHED_VIDEOS_ERROR,
    PARTICIPATION_NFTS_LIST_IN_PROGRESS,
    PARTICIPATION_NFTS_LIST_SUCCESS,
    PARTICIPATION_NFTS_LIST_ERROR,
    TOKEN_PROTECTED_TYPE_SWITCH_SET,
    VOD_INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS,
    VOD_INTERACTIVE_VIDEOS_FETCH_SUCCESS,
    VOD_INTERACTIVE_VIDEOS_FETCH_ERROR,
    FETCH_IV_STATS_IN_PROGRESS,
    FETCH_IV_STATS_SUCCESS,
    FETCH_IV_STATS_ERROR,
    GATED_UNTIL_VALUE_SET,
    FETCH_NETWORKS_ERROR,
    FETCH_NETWORKS_IN_PROGRESS,
    FETCH_NETWORKS_SUCCESS,
    SELECTED_NETWORK_SET,
    FETCH_PROPOSALS_IN_PROGRESS,
    FETCH_PROPOSALS_SUCCESS,
    FETCH_PROPOSALS_ERROR,
    SELECTED_PROPOSAL_SET,
    FETCH_NETWORK_DETAILS_IN_PROGRESS,
    FETCH_NETWORK_DETAILS_SUCCESS,
    FETCH_NETWORK_DETAILS_ERROR,
    FETCH_PROPOSAL_DETAILS_IN_PROGRESS,
    FETCH_PROPOSAL_DETAILS_SUCCESS,
    FETCH_PROPOSAL_DETAILS_ERROR,
    STAKING_AMOUNT_VALUE_SET,
    SELECTED_VALIDATOR_VALUE_SET,
    FETCH_VALIDATORS_IN_PROGRESS,
    FETCH_VALIDATORS_SUCCESS,
    FETCH_VALIDATORS_ERROR,
    MIN_DELEGATION_AMOUNT_SET,
    APR_FETCH_IN_PROGRESS,
    APR_FETCH_SUCCESS,
    APR_FETCH_ERROR,
    NETWORK_BALANCE_FETCH_IN_PROGRESS,
    NETWORK_BALANCE_FETCH_SUCCESS,
    NETWORK_BALANCE_FETCH_ERROR,
} from '../../constants/interactiveVideos';
import newFile from './new';
import overlayFile from './overlay';
import statsFile from './stats';
import { DEFAULT_LIMIT, DEFAULT_SEARCH, DEFAULT_SKIP } from '../../config';
import { INTERACTIVE_VIDEO_FETCH_SUCCESS } from '../../constants/interactiveVideos/new';
import { EMPTY_VALUES_SET } from '../../constants/createAssets';
import videoDrops from './videoDrops';
import { DISCONNECT_SET } from '../../constants/account';

const ivChannelTabValue = (state = 'interactive-videos', action) => {
    if (action.type === IV_CHANNEL_TAB_VALUE_SET) {
        return action.value;
    }

    return state;
};

const interactiveTab = (state = 'all', action) => {
    if (action.type === INTERACTIVE_TABS_VALUE_SET) {
        return action.value;
    }

    return state;
};

const interactiveSearch = (state = '', action) => {
    if (action.type === INTERACTIVE_SEARCH_VALUE_SET) {
        return action.value;
    }

    return state;
};

const myInteractiveVideos = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    search: DEFAULT_SEARCH,
    total: null,
}, action) => {
    switch (action.type) {
    case MY_INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case MY_INTERACTIVE_VIDEOS_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                search: action.search,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                skip: action.skip,
                limit: action.limit,
                search: action.search,
                total: action.total,
            };
        }
    case MY_INTERACTIVE_VIDEOS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const interVideos = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    search: DEFAULT_SEARCH,
    total: null,
}, action) => {
    switch (action.type) {
    case INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case INTERACTIVE_VIDEOS_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                search: action.search,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                skip: action.skip,
                limit: action.limit,
                search: action.search,
                total: action.total,
            };
        }
    case INTERACTIVE_VIDEOS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const vodInterVideos = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    search: DEFAULT_SEARCH,
    total: null,
}, action) => {
    switch (action.type) {
    case VOD_INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case VOD_INTERACTIVE_VIDEOS_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                search: action.search,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                skip: action.skip,
                limit: action.limit,
                search: action.search,
                total: action.total,
            };
        }
    case VOD_INTERACTIVE_VIDEOS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const activeInterVideos = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    search: DEFAULT_SEARCH,
    total: null,
}, action) => {
    switch (action.type) {
    case ACTIVE_INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ACTIVE_INTERACTIVE_VIDEOS_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                search: action.search,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                skip: action.skip,
                limit: action.limit,
                search: action.search,
                total: action.total,
            };
        }
    case ACTIVE_INTERACTIVE_VIDEOS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const upcomingInterVideos = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    search: DEFAULT_SEARCH,
    total: null,
}, action) => {
    switch (action.type) {
    case UPCOMING_INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case UPCOMING_INTERACTIVE_VIDEOS_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                search: action.search,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                skip: action.skip,
                limit: action.limit,
                search: action.search,
                total: action.total,
            };
        }
    case UPCOMING_INTERACTIVE_VIDEOS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const publishedVideos = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    search: DEFAULT_SEARCH,
    status: null,
    publishStatuses: null,
    total: null,
}, action) => {
    switch (action.type) {
    case FETCH_PUBLISHED_VIDEOS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_PUBLISHED_VIDEOS_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                search: action.search,
                status: action.status,
                publishStatuses: action.publishStatuses,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                skip: action.skip,
                limit: action.limit,
                search: action.search,
                status: action.status,
                publishStatuses: action.publishStatuses,
                total: action.total,
            };
        }
    case FETCH_PUBLISHED_VIDEOS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const gatedInteractions = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
    search: '',
}, action) => {
    switch (action.type) {
    case FETCH_GATED_INTERACTIONS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_GATED_INTERACTIONS_SUCCESS: {
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
                search: action.search,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
                search: action.search,
            };
        }
    }
    case FETCH_GATED_INTERACTIONS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const videoMintsList = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
    search: '',
}, action) => {
    switch (action.type) {
    case FETCH_VIDEO_MINTS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_VIDEO_MINTS_SUCCESS: {
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
                search: action.search,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
                search: action.search,
            };
        }
    }
    case FETCH_VIDEO_MINTS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const participationNftsList = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
    search: '',
}, action) => {
    switch (action.type) {
    case PARTICIPATION_NFTS_LIST_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PARTICIPATION_NFTS_LIST_SUCCESS: {
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
                search: action.search,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
                search: action.search,
            };
        }
    }
    case PARTICIPATION_NFTS_LIST_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const publishVisibilityTab = (state = 'public', action) => {
    if (action.type === PUBLISH_VISIBILITY_TAB_SET) {
        return action.value;
    }

    return state;
};

const publishScheduleTab = (state = 'now', action) => {
    if (action.type === PUBLISH_SCHEDULE_TAB_SET) {
        return action.value;
    }

    return state;
};

const publishScheduleStartTime = (state = {
    value: null,
    valid: true,
}, action) => {
    switch (action.type) {
    case PUBLISH_SCHEDULE_START_TIME_SET:
        return {
            value: action.value,
            valid: action.valid,
        };

    default:
        return state;
    }
};

const publishWhiteList = (state = [], action) => {
    switch (action.type) {
    case PUBLISH_WHITELIST_SET:
        return action.value;
    case PUBLISH_WHITELIST_UNSET:
        return [];
    default:
        return state;
    }
};

const publishBlackListAccounts = (state = [], action) => {
    switch (action.type) {
    case PUBLISH_BLOCK_LIST_ACCOUNTS_SET:
        return action.value;

    default:
        return state;
    }
};

const publishEligibleCollections = (state = {
    value: [],
    valid: true,
}, action) => {
    switch (action.type) {
    case PUBLISH_ELIGIBLE_COLLECTIONS_SET:
        return {
            value: action.value,
            valid: action.valid,
        };

    default:
        return state;
    }
};

const gatedUntilValue = (state = {
    value: null,
}, action) => {
    switch (action.type) {
    case GATED_UNTIL_VALUE_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const tokenProtectedSwitch = (state = {
    value: false,
    type: 'interactions',
}, action) => {
    switch (action.type) {
    case TOKEN_PROTECTED_SWITCH_SET:
        return {
            ...state,
            value: action.value,
        };
    case TOKEN_PROTECTED_TYPE_SWITCH_SET:
        return {
            ...state,
            type: action.value,
        };

    default:
        return state;
    }
};

const editInteractionCard = (state = {
    open: false,
    value: {},
    index: null,
}, action) => {
    switch (action.type) {
    case EDIT_INTERACTION_CARD_SHOW:
        return {
            ...state,
            open: true,
            value: action.value,
            index: action.index,
        };
    case EDIT_INTERACTION_CARD_HIDE:
        return {
            ...state,
            open: false,
            value: {},
            index: null,
        };
    case EMPTY_VALUES_SET:
        return {
            ...state,
            open: false,
            value: {},
            index: null,
        };
    default:
        return state;
    }
};

const addInteraction = (state = false, action) => {
    if (action.type === ADD_INTERACTION_SET) {
        return action.value;
    }

    return state;
};

// asset info
const videoTitle = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case ASSET_INFO_VIDEO_TITLE_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case INTERACTIVE_VIDEO_FETCH_SUCCESS:
        return {
            ...state,
            value: action.result && action.result.title,
        };
        // case CLEAR_ITC_MODULE_SET:
        //     return {
        //         value: '',
        //         valid: true,
        //     };
    default:
        return state;
    }
};

const videoDescription = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case ASSET_INFO_VIDEO_DESCRIPTION_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case INTERACTIVE_VIDEO_FETCH_SUCCESS:
        return {
            ...state,
            value: action.result && action.result.description,
        };
        // case CLEAR_ITC_MODULE_SET:
        //     return {
        //         value: '',
        //         valid: true,
        //     };
    default:
        return state;
    }
};

const videoSourceFile = (state = {
    value: '',
    valid: true,
    type: null,
}, action) => {
    switch (action.type) {
    case ASSET_INFO_VIDEO_SOURCE_FILE_SET:
        return {
            value: action.value,
            valid: action.valid,
            type: action.typp,
        };
    case INTERACTIVE_VIDEO_FETCH_SUCCESS:
        return {
            ...state,
            value: action.result && action.result.video_thumbnail,
        };
        // case CLEAR_ITC_MODULE_SET:
        //     return {
        //         value: '',
        //         valid: true,
        //         type: null,
        //     };
    default:
        return state;
    }
};

const publishDialog = (state = {
    open: false,
}, action) => {
    switch (action.type) {
    case PUBLISH_DIALOG_SHOW:
        return {
            open: true,
        };
    case PUBLISH_DIALOG_HIDE:
        return {
            open: false,
        };
    default:
        return state;
    }
};

const collectionLength = (state = {
    shake: false,
}, action) => {
    switch (action.type) {
    case SELECTED_COLLECTION_SET:
        return {
            shake: action.value,
        };
    default:
        return state;
    }
};

const ivStats = (state = {
    inProgress: false,
    result: {},
}, action) => {
    switch (action.type) {
    case FETCH_IV_STATS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_IV_STATS_SUCCESS:
        return {
            ...state,
            inProgress: false,
            result: action.value,
        };
    case FETCH_IV_STATS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const networks = (state = {
    inProgress: false,
    result: [],
}, action) => {
    switch (action.type) {
    case FETCH_NETWORKS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_NETWORKS_SUCCESS:
        return {
            ...state,
            inProgress: false,
            result: action.value,
        };
    case FETCH_NETWORKS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const networkDetails = (state = {
    inProgress: false,
    result: [],
}, action) => {
    switch (action.type) {
    case FETCH_NETWORK_DETAILS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_NETWORK_DETAILS_SUCCESS:
        return {
            ...state,
            inProgress: false,
            result: action.value,
        };
    case FETCH_NETWORK_DETAILS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const selectedNetwork = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case SELECTED_NETWORK_SET:
        return {
            value: action.value,
        };
    default:
        return state;
    }
};

const proposals = (state = {
    inProgress: false,
    result: {},
}, action) => {
    switch (action.type) {
    case FETCH_PROPOSALS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_PROPOSALS_SUCCESS:
        return {
            ...state,
            inProgress: false,
            result: action.value,
        };
    case FETCH_PROPOSALS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const proposalDetails = (state = {
    inProgress: false,
    result: {},
}, action) => {
    switch (action.type) {
    case FETCH_PROPOSAL_DETAILS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_PROPOSAL_DETAILS_SUCCESS:
        return {
            ...state,
            inProgress: false,
            result: action.value,
        };
    case FETCH_PROPOSAL_DETAILS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const selectedProposal = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case SELECTED_PROPOSAL_SET:
        return {
            value: action.value,
        };
    default:
        return state;
    }
};

const stakingAmount = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case STAKING_AMOUNT_VALUE_SET:
        return {
            value: action.value,
        };
    default:
        return state;
    }
};

const minDelegationAmount = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case MIN_DELEGATION_AMOUNT_SET:
        return {
            value: action.value,
        };
    default:
        return state;
    }
};

const selectedValidator = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case SELECTED_VALIDATOR_VALUE_SET:
        return {
            value: action.value,
        };
    default:
        return state;
    }
};

const apr = (state = {
    inProgress: false,
    nominalAPR: null,
    actualAPR: null,
}, action) => {
    switch (action.type) {
    case APR_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case APR_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            nominalAPR: action.nominalAPR,
            actualAPR: action.actualAPR,
        };
    case APR_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const balance = (state = {
    result: [],
    inProgress: false,
    address: '',
}, action) => {
    switch (action.type) {
    case NETWORK_BALANCE_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case NETWORK_BALANCE_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            result: action.value,
            address: action.address,
        };
    case NETWORK_BALANCE_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            result: [],
        };
    default:
        return state;
    }
};

const validators = (state = {
    inProgress: false,
    result: {},
}, action) => {
    switch (action.type) {
    case FETCH_VALIDATORS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_VALIDATORS_SUCCESS:
        return {
            ...state,
            inProgress: false,
            result: action.value,
        };
    case FETCH_VALIDATORS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    ivChannelTabValue,
    interactiveTab,
    interactiveSearch,
    newFile,
    overlayFile,
    statsFile,
    videoDrops,
    myInteractiveVideos,
    interVideos,
    vodInterVideos,
    activeInterVideos,
    upcomingInterVideos,
    publishedVideos,
    gatedInteractions,
    videoMintsList,
    participationNftsList,
    ivStats,

    publishVisibilityTab,
    publishScheduleTab,
    publishScheduleStartTime,
    publishWhiteList,
    publishBlackListAccounts,
    publishEligibleCollections,
    tokenProtectedSwitch,
    gatedUntilValue,

    editInteractionCard,
    addInteraction,

    videoTitle,
    videoDescription,
    videoSourceFile,

    publishDialog,
    collectionLength,

    networks,
    networkDetails,
    selectedNetwork,
    proposals,
    proposalDetails,
    selectedProposal,

    stakingAmount,
    minDelegationAmount,
    selectedValidator,
    apr,
    balance,
    validators,
});
