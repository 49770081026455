import React from 'react';
import { config } from '../config';
import inputIcon, { ReactComponent as InputIcon } from '../assets/interactive/input_text.svg';
import mcqIcon, { ReactComponent as McqIcon } from '../assets/interactive/quiz.svg';
import pollIcon, { ReactComponent as PollIcon } from '../assets/interactive/poll.svg';
import seekIcon, { ReactComponent as SeekIcon } from '../assets/interactive/seek.svg';
import
// delegateIcon,
{ ReactComponent as DelegateIcon } from '../assets/interactive/delegate_fungibles.svg';
import voteIcon, { ReactComponent as VoteIcon } from '../assets/interactive/vote_proposals.svg';
import stakingIcon, { ReactComponent as StakingIcon } from '../assets/interactive/staking.svg';

export const tokensList = [{
    network: config,
    value: 'uflix',
    label: 'FLIX',
    status: 'ENABLED',
}];

export const customSchema = {
    name: 'Custom Schema',
    schema: {
        id: 'custom_schema',
        properties: {},
        required: [],
        type: 'object',
    },
};

export const noSchema = {
    name: 'No Schema',
};

export const schemaPropertyTypes = [{
    name: 'string',
}, {
    name: 'boolean',
}, {
    name: 'number',
}, {
    name: 'date',
}, {
    name: 'textarea',
}];

export const streamDestinations = [{
    name: 'YouTube',
    value: 'youtube',
}, {
    name: 'X',
    value: 'x',
}, {
    name: 'Facebook',
    value: 'facebook',
}, {
    name: 'Instagram',
    value: 'instagram',
}, {
    name: 'Twitch',
    value: 'twitch',
}];

export const durationOptions = [{
    name: '10 sec',
    value: 10,
}, {
    name: '20 sec',
    value: 20,
}, {
    name: '30 sec',
    value: 30,
}, {
    name: '40 sec',
    value: 40,
// }, {
//     name: 'infinite',
//     value: 'infinite',
}];

export const typeOptions = [{
    img: pollIcon,
    name: 'POLL',
    value: 'POLL',
}, {
    img: inputIcon,
    name: 'Short answer interactions',
    value: 'INPUT_ANSWER',
}, {
    img: mcqIcon,
    name: 'Quiz',
    value: 'MCQ_SA',
}, {
    img: voteIcon,
    name: 'Governance Proposal',
    value: 'VOTE_ON_PROPOSAL',
}, {
    img: stakingIcon,
    name: 'Staking',
    value: 'DELEGATE_FUNGIBLE',
}];

export const typeOptionsAll = [{
    img: pollIcon,
    name: 'POLL',
    value: 'POLL',
}, {
    img: inputIcon,
    name: 'Short answer interactions',
    value: 'INPUT_ANSWER',
}, {
    img: mcqIcon,
    name: 'Quiz',
    value: 'MCQ_SA',
}, {
    img: voteIcon,
    name: 'Governance Proposal',
    value: 'VOTE_ON_PROPOSAL',
}, {
    img: stakingIcon,
    name: 'Staking',
    value: 'DELEGATE_FUNGIBLE',
}, {
    img: seekIcon,
    name: 'SEEK',
    value: 'SEEK',
    coming: true,
// }, {
//     img: delegateIcon,
//     name: 'Delegate fungibles',
//     value: 'Delegate fungibles',
//     coming: true,
}];

export const defaultProposalOptions = [{
    text: '👍 Yes',
    type: 'TEXT',
}, {
    text: '🚫 No with Veto',
    type: 'TEXT',
}, {
    text: '👎 No',
    type: 'TEXT',
}, {
    text: '⛔️ Abstain',
    type: 'TEXT',
}];

export const IVOptionsSVG = (value) => {
    switch (value) {
    case 'POLL':
        return <PollIcon/>;
    case 'INPUT_ANSWER':
        return <InputIcon/>;
    case 'MCQ_SA':
    case 'MCQ':
        return <McqIcon/>;
    case 'SEEK':
        return <SeekIcon/>;
    case 'Delegate fungibles':
        return <DelegateIcon/>;
    case 'VOTE_ON_PROPOSAL':
        return <VoteIcon/>;
    case 'DELEGATE_FUNGIBLE':
        return <StakingIcon/>;
    default:
        return null;
    }
};

export const suffixOptions = [{
    name: 'NFT - 1',
    value: ' - ',
}, {
    name: 'NFT #1',
    value: ' #',
}, {
    name: 'NFT (1)',
    value: ' ()',
}, {
    name: 'No Suffix',
    value: null,
}];

export const itcSuffixOptions = [{
    name: 'NFT -1',
    value: '-',
}, {
    name: 'NFT #1',
    value: '#',
}, {
    name: 'No Suffix',
    value: null,
}];

export const alphabeticalOrder = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
